<template>
	<Modal
		class="set-form-modal"
		:value="visible"
		title="表单预览"
		width="1200"
		:styles="{ top: '20px' }"
		:fullscreen="fullscreen"
		:scrollable="true"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div style="height: 100%; overflow: auto">
			<designTemplate
				:componentList="componentList"
				showType="preview"
			></designTemplate>
		</div>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/form"
import { mapState } from "vuex"
import designTemplate from "@/components/BusinessComponents/FormDesign/designTemplate.vue"

const { apiGet } = api

export default {
	name: "FormPreview",
	props: ["visible", "id"],
	components: {
		designTemplate
	},
	data() {
		return {
			loading: false,
			fullscreen: false,
			html: "",
			componentList: []
		}
	},
	computed: {
		...mapState({
			contentHeight: state => state.contentHeight - 180
		}),
		conHeight() {
			return this.fullscreen
				? document.body.clientHeight - 145
				: this.contentHeight
		},
		conWidth() {
			return this.fullscreen ? document.body.clientWidth - 100 : 725
		}
	},
	watch: {
		visible() {
			if (this.visible && this.id) {
				this.$asyncDo(async () => {
					this.loading = true
					const res = await apiGet({ id: this.id })
					if (res) {
						const { componentList } = res.data
						this.componentList = componentList
					}
					this.loading = false
				})
			}
		}
	},
	methods: {
		onCancel() {
			this.fullscreen = false
			this.$emit("onCancel")
		}
		// printForm() {
		//   let isEmpty = true;
		//   if (['document'].indexOf(this.type) > -1) {
		//     isEmpty = false;
		//   }
		//   printForm(document.getElementById('formPreview'), { isEmpty });
		// },
	}
}
</script>

<style lang="less">
.set-form-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
.preview-content {
	margin: 0 auto;
	border: 1px solid #ccc;
	overflow-x: auto;
	overflow-y: scroll;
	padding: 5px;
	table {
		width: 100% !important;
		/*table-layout: fixed;*/
	}
	color: #000;
	p {
		margin-top: 1em;
		margin-bottom: 1em;
	}
	input[type="text"],
	input[type="password"],
	input[type="number"],
	textarea {
		-webkit-appearance: textfield;
	}
}
</style>
