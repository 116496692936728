import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/common/form-template"

export default {
	...commonApi(moduleName),
	// 发布
	apiPublish: data =>
		request(`${moduleName}/${data.id}/publish`, {
			method: "post"
		}),
	// 获取数据绑定类型
	apiBindTypes: () =>
		request("/flowable/form-data/bind-types", {
			method: "get"
		}),
	// 获取数据绑定项目类型
	apiBindProTypes: () =>
		request("/flowable/form-data/auto-bind-types", {
			method: "get"
		}),
	// 提交表单设计
	apiFormSubmit: (data, id) =>
		request(`${moduleName}/${id}/submit`, {
			method: "put",
			body: data
		}),
	apiCopy: data =>
		request(`${moduleName}/${data.id}/copy`, {
			method: "post",
			body: data
		}),
	// 获取表单文件占位符列表
	getFormFilePlaceholder: data =>
		request("/common/form-file/placeholders", {
			method: "get",
			params: data
		}),
	// 获取绑定文件列表
	getFormFileList: data =>
		request("/common/form-file/bind-files", {
			method: "get",
			params: data
		}),
	// 表单绑定子流程配置文件
	apiBindFile: data =>
		request(`/common/form-template/${data.id}/form-file-bind`, {
			method: "post",
			body: data
		})
}
